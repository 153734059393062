/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import MainLayout from "./main-layout"
import Breadcrumbs from "../components/breadcrumbs"
import Background from "../components/background"
import styled from "@emotion/styled"
import VerticalLinesBackground from "../components/vertical-lines-background"

export default function PageLayout({
  children,
  title,
  mainImage,
  showBreadcrumbs,
  breadcrumbsPaths,
  breadcrumbsStyle,
  opacity,
  backgroundColor,
  maxHeight,
}) {
  return (
    <MainLayout title={title}>
      <StyledBackground fluid={mainImage}>
        <VerticalLinesBackground
          backgroundColor={backgroundColor}
          opacity={opacity}
        >
          <div
            sx={{
              display: "flex",
              height: [260, 500, "100vh"],
              maxHeight: maxHeight,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Styled.h1 sx={{ color: "lightHeading" }}>{title}</Styled.h1>
          </div>
        </VerticalLinesBackground>
      </StyledBackground>
      <div sx={{ bg: "background" }}>
        {showBreadcrumbs && (
          <Breadcrumbs
            staticPaths={breadcrumbsPaths}
            style={breadcrumbsStyle}
          />
        )}
        {children}
      </div>
    </MainLayout>
  )
}

PageLayout.defaultProps = {
  breadcrumbsStyle: { mb: 4 },
  showBreadcrumbs: true,
  maxHeight: 685,
}

const StyledBackground = styled(Background)`
  background-attachment: fixed;
  overflow: hidden;
`
