/** @jsx jsx */
import { Styled, jsx } from "theme-ui"
import { Fragment } from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import Wrapper from "./wrapper"
import useNavigationsQuery from "../hooks/useNavigationsQuery"
import Icon from "react-icons-kit"
import { chevronLeft } from "react-icons-kit/ionicons/chevronLeft"
import useLanguage from "../hooks/useLanguage"

export default function Breadcrumbs({ style, staticPaths }) {
  const { site } = useNavigationsQuery()
  const { pathname } = useLocation()
  const paths = pathname.split("/").filter(path => !!path)
  const isAR = useLanguage() === "ar"

  return (
    <div sx={{ ...styles.container, ...style }}>
      <Wrapper>
        <Styled.a sx={styles.linkStyle} as={Link} to={isAR ? "/" : "/en/"}>
          {isAR ? "نمر العقارية" : "NMRRE"}
        </Styled.a>
        {staticPaths
          ? staticPaths.map(({ title, path }) => (
              <Item
                isAR={isAR}
                pathname={pathname}
                title={title}
                pathName={path && path}
              />
            ))
          : paths.map((path, index) => {
              const { title, path: pathName } =
                site.siteMetadata.navigations.find(navigation =>
                  navigation.path.includes(path)
                ) || {}
              return (
                <Item
                  isAR={isAR}
                  key={index}
                  pathname={pathname}
                  title={title}
                  pathName={pathName}
                  path={path}
                />
              )
            })}
      </Wrapper>
    </div>
  )
}

function Item({ pathName, pathname, title, path, isAR }) {
  return (
    <Fragment>
      <div
        sx={{
          ...styles.iconWrapper,
          transform: !isAR && "rotate(180deg)",
        }}
      >
        <Icon
          style={{
            marginBottom: !isAR && 3,
          }}
          icon={chevronLeft}
          size={12}
        />
      </div>
      <Styled.a
        as={pathName && pathName !== pathname ? Link : "span"}
        to={pathName}
      >
        {title || path}
      </Styled.a>
    </Fragment>
  )
}

const styles = {
  container: {
    borderBottom: "1px solid",
    borderColor: "muted",
  },
  iconWrapper: {
    mx: 1,
    display: "inline-block",
  },
  linkStyle: {
    height: 46,
    lineHeight: "46px",
    display: "inline-block",
  },
}
