/** @jsx jsx */
import { jsx } from "theme-ui"
import PageLayout from "./page-layout"
import ProjectsList from "../containers/projects-list"
import { Fragment } from "react"
import { graphql } from "gatsby"

export default function ProjectsLayout({ data }) {
  const {
    services,
    markdownRemark: {
      frontmatter: { bg, title },
    },
  } = data

  return (
    <Fragment>
      <PageLayout
        showBreadcrumbs={false}
        backgroundColor="#000"
        opacity={0.4}
        mainImage={bg?.childImageSharp?.fluid || {}}
        title={title}
      >
        <ProjectsList list={services.edges} />
      </PageLayout>
    </Fragment>
  )
}

export const projectsQuery = graphql`
  query($lang: String!) {
    markdownRemark(
      frontmatter: { lang: { eq: $lang } }
      fileAbsolutePath: { regex: "/pages/projects.md/" }
    ) {
      frontmatter {
        title
        bg {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    services: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/projects//" }
        frontmatter: { lang: { eq: $lang } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subTitle
            description
            bg {
              childImageSharp {
                fluid(maxWidth: 1800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            img {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            previewImage {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            logo {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            footerImgs {
              image {
                childImageSharp {
                  fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            website
            type
          }
        }
      }
    }
  }
`
