/** @jsx jsx */
import { useState } from "react"

import ProjectCard from "../components/project-card"
import { jsx, Grid } from "theme-ui"
import Wrapper from "../components/wrapper"
import useLanguage from "../hooks/useLanguage"
import { Transition } from "react-spring/renderprops"
import { animated } from "react-spring"

function Tab({ active, ...props }) {
  const activeStyle = {
    backgroundColor: "#dfdfdf",
    color: "#222",
  }
  return (
    <span
      style={active ? activeStyle : {}}
      sx={{
        cursor: "pointer",
        display: "inline-block",
        p: 18,
        fontWeight: "400",
        backgroundColor: "background",

        "&:hover": {
          ...activeStyle,
        },
      }}
      {...props}
    />
  )
}

export default function ProjectsList({ list }) {
  const [selected, setSelected] = useState("all")
  const isAR = useLanguage() === "ar"
  const handleSelection = selection => () => setSelected(selection)
  const homeType = isAR ? "سكني" : "Home"
  const commercialType = isAR ? "تجاري" : "Commercial"
  return (
    <div sx={{ backgroundColor: "light", pt: 3 }}>
      <Wrapper>
        <div
          sx={{
            mb: 4,
            textAlign: "center",
          }}
        >
          <Tab active={selected === "all"} onClick={handleSelection("all")}>
            {isAR ? "كل المشاريع" : "All Projects"}
          </Tab>
          <Tab
            active={selected === "commercial"}
            onClick={handleSelection("commercial")}
          >
            {commercialType}
          </Tab>
          <Tab active={selected === "home"} onClick={handleSelection("home")}>
            {homeType}
          </Tab>
        </div>
      </Wrapper>
      <Grid gap={4} columns={[1, 2, 3]}>
        <Transition
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
          items={selected}
          key={item => item}
          trail={100}
        >
          {type => props => {
            const projects =
              type === "all"
                ? list
                : list.filter(({ node }) => node.frontmatter.type === type)
            return projects.map(({ node }) => (
              <animated.div style={props}>
                <ProjectCard
                  key={node.id}
                  typeText={
                    node.frontmatter.type === "home" ? homeType : commercialType
                  }
                  {...node.frontmatter}
                  {...node.fields}
                />
              </animated.div>
            ))
          }}
        </Transition>
      </Grid>
    </div>
  )
}
