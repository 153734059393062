/** @jsx jsx */
import Image from "gatsby-image"

import { Styled, jsx } from "theme-ui"
import { Link } from "gatsby"

export default function ProjectCard({ previewImage, typeText, title, slug }) {
  return (
    <Link to={slug}>
      <div
        sx={{
          mx: 15,
          mb: 30,
          position: "relative",
          overflow: "hidden",
          "&:hover": {
            img: {
              transform: "scale(1.1)",
            },
          },
        }}
      >
        <div
          sx={{
            width: "100%",
          }}
        >
          <Image
            style={{ height: "inherit" }}
            imgStyle={{ objectFit: "cover", transition: "all 1.5s" }}
            fluid={previewImage?.childImageSharp?.fluid || {}}
          />
        </div>

        <div
          sx={{
            cursor: "pointer",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: 0,
            transition: "all 0.4s",

            div: {
              position: "absolute",
              top: "54%",
              width: "100%",
              textAlign: "center",
              textShadow: "0px 0px 20px rgba(0, 0, 0, 0.3)",
              transition: "all 0.4s",
              transform: "translateY(-50%)",
            },
            "::before": {
              zIndex: 0,
              content: '""',
              position: "absolute",
              opacity: 0,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              color: "background",
              backgroundColor: "rgba(0,0,0,0.5)",
              transition: "all 0.4s",
            },

            "&:hover": {
              opacity: 1,
              top: 0,
              div: {
                top: "50%",
              },
              "::before": {
                opacity: 1,
              },
            },
          }}
        >
          <div sx={{ color: "white", textAlign: "center", zIndex: 1 }}>
            <span>{typeText}</span>
            <Styled.h4 sx={{ mt: 2, color: "white" }}>{title}</Styled.h4>
          </div>
        </div>
      </div>
    </Link>
  )
}
